import * as React from "react";
import classNames from "classnames";
// markup
const Container = ({ children, className }) => {
  return (
    <div className={classNames("container", className)}>
      {children}
    </div>
  );
};

export default Container;
